import { UserOrder } from '@app/models/user.model'
import { FlamingoFormSnapshot } from '@app/modules/flamingo/models/flamingo-snapshot.model'
import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { toLocalMidnight } from '@app/utils/date.utils'
import { CustomForm } from '../components/custom-form/models/custom-form.model'
import { CalibrationResultStatusEnum } from './calibration-result-status.enum'
import { CalibrationResultStatus } from './calibration-result-status.model'
import { CalibrationResult } from './calibration-result.model'
import { CalibrationStatusEnum } from './calibration-status.enum'
import { CalibrationStatus } from './calibration-status.model'
import { CalibrationTemplate } from './calibration-template.model'
import { CalibrationChecklist } from './checklist.model'
import { ReviewStatusEnum } from './review-status.enum'

export interface Cylinder {
    referenceId: string
    content: string
    expiryDate: Date
    isExpired?: boolean
    isUsedLatest?: boolean
}

export class CalibrationDetails {
    public id: string
    public plantCode: string
    public workOrderNumber: string
    public workOrderDescription: string
    public repairWorkOrderNumber: string
    public notificationNumber: string
    public equipmentId: string
    public equipmentTag: string
    public techIdentificationNumber: string
    public functionalLocation: string
    public testEquipments: any
    public templateId: number
    public templateTypeId: number
    public processId: number
    public processName: string
    public calibrationResultStatus?: CalibrationResultStatus
    public calibrationStatus: CalibrationStatus
    public calibrationResult: CalibrationResult
    public customFormTemplate: CustomForm
    public calibrationTemplate?: CalibrationTemplate
    public calibrationChecklist: CalibrationChecklist[]
    public pmPerformedDate: Date
    public modifiedDate: Date
    public pmPerformedTechnicians: UserOrder[]
    public isBookmarked?: boolean
    public isUsingNoTemplateOverridden?: boolean
    public reviewStatusId: number
    public reviewer: string
    public reviewDate: Date
    public comments: string
    public reasonForNotComplete: string
    public equipmentSerialNumber: string
    public equipmentReplacedDate: Date
    public replacedEquipmentSerialNumber: string
    public modelNumber: string
    public manufacturer: string
    public replacedModelNumber: string
    public replacedManufacturer: string
    public equipmentReplacedTechnicians: UserOrder[]
    public finalPMResultStatus: CalibrationResultStatus
    public isFinalPMResultStatusManuallySet: boolean
    public dataSource: string
    public flamingoForm: FlamingoFormSnapshot[]
    public atoNumber: string
    public atoDate: Date
    public cylinders: Cylinder[]
    public isTemplateActive: boolean

    public static isNoTemplate(calibrationDetails: CalibrationDetails): boolean {
        if (calibrationDetails.calibrationStatus.id === CalibrationStatusEnum.Completed && (
            calibrationDetails?.finalPMResultStatus?.id === CalibrationResultStatusEnum.Deferred ||
            calibrationDetails?.finalPMResultStatus?.id === CalibrationResultStatusEnum.NotTested)) {
            return false
        }

        return !calibrationDetails.calibrationTemplate || !calibrationDetails.templateTypeId
    }



    public static createBaseObject(): CalibrationDetails {
        return {
            reviewer: undefined,
            reviewDate: undefined,
            reasonForNotComplete: undefined,
            equipmentSerialNumber: '',
            equipmentReplacedDate: undefined,
            modelNumber: '',
            manufacturer: '',
            replacedModelNumber: '',
            replacedManufacturer: '',
            repairWorkOrderNumber: undefined,
            calibrationResultStatus: undefined,
            replacedEquipmentSerialNumber: '',
            testEquipments: [],
            equipmentReplacedTechnicians: [],
            reviewStatusId: ReviewStatusEnum.NotReady,
            pmPerformedDate: toLocalMidnight(new Date()),
            calibrationResult: { results: [{ resultSetName: '', resultSet: [] }] } as CalibrationResult,
            calibrationStatus: { id: CalibrationStatusEnum.NotStarted, name: mrmaStatus.calibrationStatus[0].name } as CalibrationStatus,
            comments: '',
            id: undefined,
            customFormTemplate: null,
            isFinalPMResultStatusManuallySet: false,
            finalPMResultStatus: undefined,
            plantCode: undefined,
            workOrderNumber: undefined,
            workOrderDescription: '',
            equipmentId: undefined,
            equipmentTag: undefined,
            templateId: undefined,
            processId: undefined,
            processName: undefined,
            calibrationChecklist: undefined,
            calibrationTemplate: null,
            pmPerformedTechnicians: [],
            notificationNumber: undefined,
            flamingoForm: [],
            cylinders: [],
            isTemplateActive: undefined
        } as CalibrationDetails
    }
}
